@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Quicksand, sans-serif;
}

body {
  background: radial-gradient(circle, #e7e6f2 0%, #fff 0%, #3962db 100%);
}

.div-hero-banner {
  background: url("fond_2.456b3d2f.webp") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px 25px;
  display: flex;
}

.div-hero-banner .glass {
  backdrop-filter: blur(6px);
  background: #ffffff1a;
  border: 1px solid #ffffff4d;
  border-radius: 15px;
  height: 95%;
  box-shadow: 0 4px 6px #0000001a;
}

.div-hero-banner .glass .navbar {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  min-height: 20%;
  padding: 10px 40px;
  display: flex;
}

.div-hero-banner .glass .navbar img {
  width: 14%;
}

.div-hero-banner .glass .navbar .menu-header {
  width: 42%;
  font-size: 20px;
}

.div-hero-banner .glass .navbar .menu-header ul {
  justify-content: space-between;
  display: flex;
}

.div-hero-banner .glass .navbar .menu-header ul li {
  border-bottom: 0 solid #4b61a6;
  margin-top: 11px;
  list-style-type: none;
  transition: border-bottom .1s ease-in;
}

.div-hero-banner .glass .navbar .menu-header ul li a {
  color: #000;
  font-weight: 700;
  text-decoration: none;
}

.div-hero-banner .glass .navbar .menu-header ul li:hover {
  border-bottom: 3.2px solid #4b61a6;
  animation: .1s ease-in barreDuBas;
}

@keyframes barreDuBas {
  from {
    border-bottom: 0 solid #4b61a6;
  }

  to {
    border-bottom: 3.5px solid #4b61a6;
  }
}

.div-hero-banner .glass .navbar .menu-header ul .btn-navbar {
  cursor: pointer;
  -webkit-backdrop-filter: blur(30px);
  background-color: #274073;
  border: 0 solid #ffffff61;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 600;
  box-shadow: 0 8px 32px #00000061;
}

.div-hero-banner .glass .navbar .menu-header ul .btn-navbar:hover {
  background-color: #4b61a6;
}

.div-hero-banner .glass .content {
  align-items: center;
  height: 80%;
  padding: 0 85px;
  display: flex;
}

.div-hero-banner .glass .content .text {
  width: 65%;
  font-size: 42px;
}

.div-hero-banner .glass .content .text .small {
  color: #000;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 800;
}

.div-hero-banner .glass .content .text .btn-icon {
  justify-content: space-between;
  width: 95%;
  height: 50px;
  margin-top: 25px;
  display: flex;
}

.div-hero-banner .glass .content .text .btn-icon .icon-instagram {
  height: 90%;
  margin-right: 10px;
}

.div-hero-banner .glass .content .text .btn-icon .icon-linkedin {
  height: 90%;
}

.div-hero-banner .glass .content .text .btn-icon .zoom:hover {
  transform: scale(1.2);
}

.div-hero-banner .glass .content .text .btn-icon .zoom {
  transition: transform .3s;
}

.div-hero-banner .glass .content .text .btn-hero-banner {
  cursor: pointer;
  color: #fff;
  -webkit-backdrop-filter: blur(30px);
  background-color: #274073;
  border: 0 solid #ffffff61;
  border-radius: 10px;
  align-items: center;
  width: fit-content;
  padding: 5px 20px;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  box-shadow: 0 8px 32px #00000061;
}

.div-hero-banner .glass .content .text .btn-hero-banner:hover {
  background-color: #4b61a6;
}

.div-hero-banner .glass .content .image {
  width: 35%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.div-hero-banner .glass .content .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}

.div-hero-banner .glass .content .image img:hover {
  transform: scale(1.2);
}

.mes-service {
  flex-direction: column;
  width: 100%;
  height: 400px;
  margin: 25px 0;
  padding: 0 115px;
  display: flex;
}

.mes-service .title {
  color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  font-size: 42px;
  font-weight: 800;
  display: flex;
}

.mes-service .content-box {
  justify-content: space-between;
  align-items: center;
  gap: 45px;
  width: 100%;
  height: 80%;
  display: flex;
}

.mes-service .content-box .box {
  -webkit-backdrop-filter: blur(30px);
  background-color: #f2f2f265;
  border: 2px solid #0000;
  border-radius: 20px;
  flex-direction: column;
  width: 25%;
  height: 80%;
  padding: 15px;
  transition: border .2s;
  display: flex;
  box-shadow: 0 8px 32px #000000ad;
}

.mes-service .content-box .box .box-feature {
  height: 100%;
}

.mes-service .content-box .box .box-feature .zoom:hover {
  transform: scale(1.1);
}

.mes-service .content-box .box .box-feature .zoom {
  transition: transform .3s;
}

.mes-service .content-box .box .box-feature .icon {
  height: 28%;
}

.mes-service .content-box .box .box-feature .text {
  width: 100%;
  height: 62%;
  padding-top: 15px;
}

.mes-service .content-box .box .box-feature .text .subtitle {
  color: #fff;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  justify-content: left;
  font-size: 20px;
  font-weight: 900;
}

.mes-service .content-box .box .box-feature .text p {
  text-align: left;
  color: #000;
  margin: 0;
  padding-top: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

.mes-service .content-box .box .box-feature .text .join-me {
  justify-content: end;
  height: 25%;
  padding-top: 15px;
  display: flex;
}

.mes-service .content-box .box .box-feature .text .join-me a {
  color: #fff;
  text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
  font-weight: 700;
  text-decoration: none;
}

.mes-service .content-box .box:hover {
  border: 2px solid #fff;
  box-shadow: 0 8px 32px #7b7b7bad;
}

.contact {
  background: url("fond-contact.4d87228c.jpg") center / cover no-repeat;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 700px;
  padding: 85px 0;
  display: flex;
}

.contact .glass {
  backdrop-filter: blur(6px);
  background: #ffffff1a;
  border: 1px solid #ffffff4d;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 65%;
  height: 100%;
  padding: 25px 100px;
  display: flex;
  box-shadow: 0 4px 6px #0000001a;
}

.contact h2 {
  color: #000;
  height: auto;
  font-size: 42px;
  font-weight: 800;
}

.contact .formulaire {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  display: flex;
}

.contact .formulaire label {
  text-align: left;
  color: #fff;
  width: 15%;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.contact .formulaire .name {
  align-items: center;
  width: 100%;
  height: 10%;
  display: flex;
}

.contact .formulaire .name input {
  text-align: left;
  color: #000;
  border-radius: 5px;
  width: 85%;
  height: 100%;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
}

.contact .formulaire .surname {
  align-items: center;
  width: 100%;
  height: 10%;
  margin-top: 15px;
  display: flex;
}

.contact .formulaire .surname input {
  text-align: left;
  color: #000;
  border-radius: 5px;
  width: 85%;
  height: 100%;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
}

.contact .formulaire .email {
  align-items: center;
  width: 100%;
  height: 10%;
  margin-top: 15px;
  display: flex;
}

.contact .formulaire .email input {
  text-align: left;
  color: #000;
  border-radius: 5px;
  width: 85%;
  height: 100%;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
}

.contact .formulaire .motif {
  align-items: center;
  width: 100%;
  height: 10%;
  margin-top: 15px;
  display: flex;
}

.contact .formulaire .motif select {
  text-align: left;
  color: #000;
  border-radius: 5px;
  width: 85%;
  height: 100%;
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
}

.contact .formulaire .message {
  align-items: center;
  width: 100%;
  height: 30%;
  margin-top: 15px;
  display: flex;
}

.contact .formulaire .message textarea {
  text-align: left;
  color: #000;
  resize: none;
  border-radius: 5px;
  width: 85%;
  height: 100%;
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
}

.contact .formulaire button {
  cursor: pointer;
  color: #fff;
  -webkit-backdrop-filter: blur(30px);
  background-color: #274073;
  border: 0 solid #ffffff61;
  border-radius: 10px;
  align-self: end;
  width: fit-content;
  margin-top: 15px;
  padding: 5px 20px;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  box-shadow: 0 8px 32px #00000061;
}

.contact .formulaire button:hover {
  background-color: #4b61a6;
}

.contact .formulaire .info {
  color: #fff;
  align-self: start;
  font-size: 18px;
  font-weight: 700;
}

.footer {
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 680px;
  display: flex;
}

.footer .content {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 85%;
  padding-top: 30px;
  display: flex;
}

.footer .content .logo p {
  color: #000;
  width: 100%;
  margin-left: 70px;
  font-size: 18px;
  font-weight: 800;
}

.footer .card {
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  max-width: 340px;
  margin: auto;
  transition: all .3s;
  display: flex;
  position: relative;
  overflow: hidden auto;
  box-shadow: 0 0 0 8px #fff3;
}

.footer .card[data-state="#about"] {
  height: 470px;
}

.footer .card[data-state="#about"] .card-main {
  padding-top: 0;
}

.footer .card[data-state="#contact"] {
  min-width: 340px;
  height: 400px;
}

.footer .card[data-state="#experience"] {
  height: 550px;
}

.footer .card.is-active .card-header {
  height: 80px;
}

.footer .card.is-active .card-cover {
  height: 100px;
  top: -50px;
}

.footer .card.is-active .card-avatar {
  width: 50px;
  height: 50px;
  bottom: 10px;
  left: 20px;
  transform: none;
}

.footer .card.is-active .card-fullname, .footer .card.is-active .card-jobtitle {
  left: 86px;
  transform: none;
}

.footer .card.is-active .card-fullname {
  font-size: 19px;
  bottom: 18px;
}

.footer .card.is-active .card-jobtitle {
  letter-spacing: 1px;
  font-size: 10px;
  bottom: 16px;
}

.footer .card-header {
  flex-shrink: 0;
  width: 100%;
  height: 200px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.footer .card-header * {
  transition: all .3s;
}

.footer .card-cover {
  will-change: top;
  filter: blur(30px);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 160px;
  transition: all .5s;
  position: absolute;
  top: -20%;
  left: 0;
  transform: scale(1.2);
}

.footer .card-avatar {
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)translateY(-64px);
  box-shadow: 0 8px 8px #0003;
}

.footer .card-fullname {
  text-align: center;
  white-space: nowrap;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-10px)translateX(-50%);
}

.footer .card-jobtitle {
  white-space: nowrap;
  opacity: .7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)translateY(-7px);
}

.footer .card-main {
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  display: flex;
  position: relative;
}

.footer .card-subtitle {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 700;
}

.footer .card-content {
  padding: 20px;
}

.footer .card-desc {
  color: #636b6f;
  margin: 0;
  font-family: DM Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}

.footer .card-social {
  align-items: center;
  margin-bottom: 15px;
  padding: 0 20px;
  display: flex;
}

.footer .card-social svg {
  fill: #a5b5ce;
  width: 16px;
  transition: all .3s;
  display: block;
}

.footer .card-social a {
  color: #8797a1;
  background-color: #5d85c10d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  transition: all .3s;
  display: inline-flex;
}

.footer .card-social a:hover svg {
  fill: #637faa;
}

.footer .card-social a:last-child {
  margin-right: 0;
}

.footer .card-buttons {
  background-color: #fff;
  margin-top: auto;
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
}

.footer .card-buttons button {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #5c5c6d;
  background: 0;
  border: 0;
  border-bottom: 3px solid #0000;
  outline: 0;
  flex: auto;
  padding: 15px 5px;
  font-family: Jost, sans-serif;
  font-size: 13px;
  font-weight: 500;
  transition: all .3s;
}

.footer .card-buttons button.is-active, .footer .card-buttons button:hover {
  color: #2b2c48;
  background: linear-gradient(#7fc7e700 0%, #cfccff33 44%, #d3e2ff66 100%);
  border-bottom: 3px solid #8a84ff;
}

.footer .card-section {
  display: none;
}

.footer .card-section.is-active {
  animation: .6s both fadeIn;
  display: block;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
  }
}

.footer .card-timeline {
  margin-top: 30px;
  position: relative;
}

.footer .card-timeline:after {
  content: "";
  content: "";
  background: linear-gradient(to top, #86d6f300 0%, #516acc 100%);
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 42px;
}

.footer .card-item {
  z-index: 1;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 20px;
  position: relative;
}

.footer .card-item:last-child {
  padding-bottom: 5px;
}

.footer .card-item:after {
  content: attr(data-year);
  text-indent: -35px;
  color: #868686b3;
  background: linear-gradient(#a0aee3 0%, #516acc 100%);
  border: 2px solid #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  font-size: 11px;
  line-height: .6;
  position: absolute;
  top: 0;
  left: 37px;
}

.footer .card-item-title {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.footer .card-item-desc {
  color: #6f6f7b;
  font-family: DM Sans, sans-serif;
  font-size: 13px;
  line-height: 1.5;
}

.footer .card-contact-wrapper {
  margin-top: 20px;
}

.footer .card-contact {
  color: #6f6f7b;
  cursor: pointer;
  align-items: center;
  font-family: DM Sans, sans-serif;
  font-size: 13px;
  line-height: 1.6;
  display: flex;
}

.footer .card-contact + .footer .card-contact {
  margin-top: 16px;
}

.footer .card-contact svg {
  border-right: 1px solid #dfe2ec;
  flex-shrink: 0;
  width: 30px;
  min-height: 34px;
  margin-right: 12px;
  padding-right: 12px;
  transition: all .3s;
}

.footer .contact-me {
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to right, #53c8efcc 0%, #516acccc 96%);
  border: 0;
  border-radius: 5px;
  outline: none;
  width: 100%;
  margin-top: 25px;
  padding: 12px 16px;
  font-family: Jost, sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s;
  box-shadow: 0 4px 6px #00000026;
}

.footer footer {
  color: #000;
  align-items: center;
  height: 12%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  backdrop-filter: blur(6px);
  background: #ffffff1a;
  border: 1px solid #888;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  width: 30%;
  height: 150px;
  margin: 15% auto;
  padding: 20px;
  display: flex;
  box-shadow: 0 4px 6px #0000001a;
}

.modal-content p {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.close {
  color: #aaa;
  align-self: flex-end;
  padding: 0 5px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

@media (width <= 575px) {
  .div-hero-banner .glass {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .div-hero-banner .glass .navbar {
    justify-content: center;
    width: 100%;
    height: 30%;
    padding: 0;
  }

  .div-hero-banner .glass .navbar .logo {
    align-items: center;
    width: 70%;
    height: 60px;
  }

  .div-hero-banner .glass .navbar .menu-header {
    display: none;
  }

  .div-hero-banner .glass .content {
    align-items: normal;
    height: 70%;
    padding: 0 20px;
  }

  .div-hero-banner .glass .content .text {
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
  }

  .div-hero-banner .glass .content .text p {
    font-size: 18px;
  }

  .div-hero-banner .glass .content .text .small {
    text-align: center;
    font-size: 14px;
    display: inline-block;
  }

  .div-hero-banner .glass .content .text .btn-icon, .div-hero-banner .glass .content .image {
    display: none;
  }

  .mes-service {
    height: auto;
    padding: 0;
  }

  .mes-service .content-box {
    flex-direction: column;
    margin-top: 25px;
    padding: 0 15px;
  }

  .mes-service .content-box .box {
    width: 100%;
  }

  .mes-service .content-box .box .box-feature .icon {
    width: 20%;
  }

  .contact {
    padding: 10px 15px;
  }

  .contact .glass {
    width: 100%;
    padding: 0;
  }

  .contact .glass h2 {
    padding: 15px 0;
    font-size: 28px;
  }

  .contact .glass .formulaire {
    margin-top: 15px;
  }

  .contact .glass .formulaire .name {
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .contact .glass .formulaire .name label {
    width: 30%;
  }

  .contact .glass .formulaire .name input {
    width: 65%;
    height: 70%;
  }

  .contact .glass .formulaire .surname {
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .contact .glass .formulaire .surname label {
    width: 30%;
  }

  .contact .glass .formulaire .surname input {
    width: 65%;
    height: 70%;
  }

  .contact .glass .formulaire .email {
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .contact .glass .formulaire .email label {
    width: 30%;
  }

  .contact .glass .formulaire .email input {
    width: 65%;
    height: 70%;
  }

  .contact .glass .formulaire .motif {
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .contact .glass .formulaire .motif label {
    width: 30%;
  }

  .contact .glass .formulaire .motif select {
    width: 65%;
    height: 70%;
    font-size: 12px;
  }

  .contact .glass .formulaire .message {
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
  }

  .contact .glass .formulaire .message label {
    width: 32%;
  }

  .contact .glass .formulaire .message textarea {
    width: 65%;
    height: 70%;
    font-size: 14px;
  }

  .contact .glass .formulaire button {
    margin: 0 10px 0 0;
  }

  .contact .glass .formulaire .info {
    width: 100%;
    padding: 25px 15px;
  }

  .footer .content {
    flex-direction: column;
    height: 90%;
    padding: 15px 10px;
  }

  .footer .content .logo {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .footer .content .logo img {
    width: 80%;
  }

  .footer .content .logo p {
    text-align: center;
    margin-top: 15px;
    margin-left: 0;
    font-size: 14px;
  }

  .footer .content .card[data-state="#experience"], .footer .content .card[data-state="#about"] {
    height: 390px;
  }

  .footer .content .card[data-state="#contact"] {
    min-width: 300px;
    height: 390px;
  }

  .footer .content .presentation {
    margin-top: 25px;
  }

  .footer footer {
    text-align: center;
    font-size: 16px;
  }
}

body {
  --sb-track-color: #85a0e975;
  --sb-thumb-color: #bfabc4;
  --sb-size: 15px;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border: 1px solid #000;
  border-radius: 2px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}
/*# sourceMappingURL=index.691be570.css.map */
