$colorBackgroundBtn: #4b61a6;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

* {
  font-family: 'Quicksand', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: radial-gradient(circle, rgb(231, 230, 242) 0%, rgb(255, 255, 255) 0%, rgb(57, 98, 219) 100%);
}

//Hero-banner glass
.div-hero-banner {
  background: url("../images/fond_2.webp") no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 25px;
  height: 100vh;

  .glass {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 95%;

    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 40px;
      color: white;
      min-height: 20%;

      img {
        width: 14%;
      }

      .menu-header {
        font-size: 20px;
        width: 42%;

        ul {
          display: flex;
          justify-content: space-between;

          li {
            list-style-type: none;
            margin-top: 11px;
            border-bottom: 0px solid $colorBackgroundBtn;
            transition: border-bottom 0.1s ease-in;

            a {
              text-decoration: none;
              color: black;
              font-weight: 700;
            }
          }

          li:hover {
            border-bottom: 3.2px solid $colorBackgroundBtn;
            animation: barreDuBas 0.1s ease-in;
          }

          @keyframes barreDuBas {
            from {
              border-bottom: 0px solid $colorBackgroundBtn;
            }

            to {
              border-bottom: 3.5px solid $colorBackgroundBtn;
            }
          }

          .btn-navbar {
            padding: 10px 20px;
            background-color: #274073;
            border-radius: 10px;
            cursor: pointer;
            font-weight: 600;
            backdrop-filter: blur(30px);
            -webkit-backdrop-filter: blur(30px);
            border: 0px solid rgba(255, 255, 255, 0.38);
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.38);
          }

          .btn-navbar:hover {
            background-color: #4b61a6;
          }
        }
      }
    }

    .content {
      height: 80%;
      display: flex;
      align-items: center;
      padding: 0px 85px;

      .text {
        font-size: 42px;
        width: 65%;

        .small {
          font-size: 22px;
          font-weight: 800;
          color: black;
          margin-top: 10px;
        }

        .btn-icon {
          height: 50px;
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          width: 95%;

          .icon-instagram {
            height: 90%;
            margin-right: 10px;
          }

          .icon-linkedin {
            height: 90%;
          }

          .zoom:hover {
            transform: scale(1.2);
          }

          .zoom {
            transition: transform 0.3s ease;
          }
        }

        .btn-hero-banner {
          padding: 5px 20px;
          background-color: #274073;
          cursor: pointer;
          font-weight: 600;
          color: white;
          width: fit-content;
          font-size: 22px;
          backdrop-filter: blur(30px);
          -webkit-backdrop-filter: blur(30px);
          border-radius: 10px;
          border: 0px solid rgba(255, 255, 255, 0.38);
          box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.38);
          display: flex;
          align-items: center;
          text-decoration: none;
        }

        .btn-hero-banner:hover {
          background-color: #4b61a6;
        }
      }

      .image {
        width: 35%;
        height: 100%;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.mes-service {
  margin: 25px 0px;
  width: 100%;
  height: 400px;
  padding: 0px 115px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 42px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 20%;
    align-items: center;
    color: black;
  }

  .content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    gap: 45px;
    width: 100%;

    .box {
      background-color: #f2f2f265;
      border-radius: 4px;
      height: 80%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      width: 25%;
      border: 2px solid transparent;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      border-radius: 20px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.68);
      transition: border 0.2s ease;

      .box-feature {
        height: 100%;

        .zoom:hover {
          transform: scale(1.1);
        }

        .zoom {
          transition: transform 0.3s ease;
        }

        .icon {
          height: 28%;
        }

        .text {
          padding-top: 15px;
          height: 62%;
          width: 100%;

          .subtitle {
            font-size: 20px;
            font-weight: 900;
            color: white;
            justify-content: left;
            text-shadow:
              -1px -1px 2 #000,
              /* Ombre noire en haut à gauche */
              1px -1px 2 #000,
              /* Ombre noire en haut à droite */
              -1px 1px 2 #000,
              /* Ombre noire en bas à gauche */
              1px 1px 2 #000;
            /* Ombre noire en bas à droite */
          }

          p {
            margin: 0;
            padding-top: 8px;
            font-weight: 700;
            text-align: left;
            line-height: 1.4;
            color: black;
            font-size: 18px;
          }

          .join-me {
            padding-top: 15px;
            height: 25%;
            display: flex;
            justify-content: end;

            a {
              text-decoration: none;
              color: none;
              color: white;
              font-weight: 700;
              text-shadow:
                -1px -1px 2 #000,
                /* Ombre noire en haut à gauche */
                1px -1px 2 #000,
                /* Ombre noire en haut à droite */
                -1px 1px 2 #000,
                /* Ombre noire en bas à gauche */
                1px 1px 2 #000;
              /* Ombre noire en bas à droite */
            }
          }
        }
      }
    }

    .box:hover {
      border: 2px solid white;
      box-shadow: 0 8px 32px 0 rgba(123, 123, 123, 0.68);
    }
  }
}

.contact {
  height: 700px;
  width: 100%;
  padding: 85px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../images/fond-contact.jpg") no-repeat center center/cover;

  .glass {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 65%;
    padding: 25px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  h2 {
    font-size: 42px;
    font-weight: 800;
    color: black;
    height: auto;
  }

  .formulaire {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    label {
      width: 15%;
      margin: 0;
      font-weight: 700;
      text-align: left;
      color: white;
      font-size: 18px;
    }

    .name {
      height: 10%;
      width: 100%;
      display: flex;
      align-items: center;

      input {
        width: 85%;
        height: 100%;
        border-radius: 5px;
        font-weight: 700;
        text-align: left;
        color: black;
        font-size: 18px;
        padding: 8px;
      }
    }

    .surname {
      height: 10%;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      input {
        width: 85%;
        height: 100%;
        border-radius: 5px;
        font-weight: 700;
        text-align: left;
        color: black;
        font-size: 18px;
        padding: 8px;
      }
    }

    .email {
      height: 10%;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      input {
        width: 85%;
        height: 100%;
        border-radius: 5px;
        font-weight: 700;
        text-align: left;
        color: black;
        font-size: 18px;
        padding: 8px;
      }
    }

    .motif {
      height: 10%;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      select {
        width: 85%;
        height: 100%;
        border-radius: 5px;
        font-weight: 700;
        text-align: left;
        color: black;
        font-size: 16px;
        padding: 8px;
      }
    }

    .message {
      height: 30%;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      textarea {
        width: 85%;
        height: 100%;
        border-radius: 5px;
        font-weight: 700;
        text-align: left;
        color: black;
        font-size: 16px;
        padding: 8px;
        resize: none;
      }
    }

    button {
      padding: 5px 20px;
      background-color: #274073;
      cursor: pointer;
      font-weight: 600;
      color: white;
      width: fit-content;
      font-size: 22px;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      border-radius: 10px;
      border: 0px solid rgba(255, 255, 255, 0.38);
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.38);
      display: flex;
      align-self: end;
      margin-top: 15px;
    }

    button:hover {
      background-color: #4b61a6;
    }

    .info {
      font-weight: 700;
      color: white;
      font-size: 18px;
      align-self: start;
    }
  }
}

.footer {
  color: black;
  height: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    height: 85%;
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .logo {

      p {
        width: 100%;
        margin-left: 70px;
        font-size: 18px;
        font-weight: 800;
        color: black;
      }
    }
  }

  .card {
    max-width: 340px;
    margin: auto;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  }

  .card[data-state="#about"] {
    height: 470px;

    .card-main {
      padding-top: 0;
    }
  }

  .card[data-state="#contact"] {
    height: 400px;
    min-width: 340px;
  }

  .card[data-state="#experience"] {
    height: 550px;
  }

  .card.is-active {
    .card-header {
      height: 80px;
    }

    .card-cover {
      height: 100px;
      top: -50px;
    }

    .card-avatar {
      transform: none;
      left: 20px;
      width: 50px;
      height: 50px;
      bottom: 10px;
    }

    .card-fullname,
    .card-jobtitle {
      left: 86px;
      transform: none;
    }

    .card-fullname {
      bottom: 18px;
      font-size: 19px;
    }

    .card-jobtitle {
      bottom: 16px;
      letter-spacing: 1px;
      font-size: 10px;
    }
  }

  .card-header {
    position: relative;
    display: flex;
    height: 200px;
    flex-shrink: 0;
    width: 100%;
    transition: 0.3s;

    * {
      transition: 0.3s;
    }
  }

  .card-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    height: 160px;
    top: -20%;
    left: 0;
    will-change: top;
    background-size: cover;
    background-position: center;
    filter: blur(30px);
    transform: scale(1.2);
    transition: 0.5s;
  }

  .card-avatar {
    width: 100px;
    height: 100px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-64px);
  }

  .card-fullname {
    position: absolute;
    bottom: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transform: translateY(-10px) translateX(-50%);
    left: 50%;
    margin-bottom: 15px;
  }

  .card-jobtitle {
    position: absolute;
    bottom: 0;
    font-size: 11px;
    white-space: nowrap;
    font-weight: 500;
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-7px);
  }

  .card-main {
    position: relative;
    flex: 1;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }

  .card-subtitle {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
  }

  .card-content {
    padding: 20px;
  }

  .card-desc {
    line-height: 1.6;
    color: #636b6f;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }

  .card-social {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 15px;

    svg {
      fill: rgb(165, 181, 206);
      width: 16px;
      display: block;
      transition: 0.3s;
    }

    a {
      color: #8797a1;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      background-color: rgba(93, 133, 193, 0.05);
      border-radius: 50%;
      margin-right: 10px;

      &:hover {
        svg {
          fill: darken(rgb(165, 181, 206), 20%);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .card-buttons {
    display: flex;
    background-color: #fff;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    left: 0;

    button {
      flex: 1 1 auto;
      user-select: none;
      background: 0;
      font-size: 13px;
      border: 0;
      padding: 15px 5px;
      cursor: pointer;
      color: #5c5c6d;
      transition: 0.3s;
      font-family: "Jost", sans-serif;
      font-weight: 500;
      outline: 0;
      border-bottom: 3px solid transparent;

      &.is-active,
      &:hover {
        color: #2b2c48;
        border-bottom: 3px solid #8a84ff;
        background: linear-gradient(to bottom,
            rgba(127, 199, 231, 0) 0%,
            rgba(207, 204, 255, 0.2) 44%,
            rgba(211, 226, 255, 0.4) 100%);
      }
    }
  }

  .card-section {
    display: none;

    &.is-active {
      display: block;
      animation: fadeIn 0.6s both;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translatey(40px);
    }

    100% {
      opacity: 1;
    }
  }

  .card-timeline {
    margin-top: 30px;
    position: relative;

    &:after {
      background: linear-gradient(to top,
          rgba(134, 214, 243, 0) 0%,
          rgba(81, 106, 204, 1) 100%);
      content: "";
      left: 42px;
      width: 2px;
      top: 0;
      height: 100%;
      position: absolute;
      content: "";
    }
  }

  .card-item {
    position: relative;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 30px;
    z-index: 1;

    &:last-child {
      padding-bottom: 5px;
    }

    &:after {
      content: attr(data-year);
      width: 10px;
      position: absolute;
      top: 0;
      left: 37px;
      width: 8px;
      height: 8px;
      line-height: 0.6;
      border: 2px solid #fff;
      font-size: 11px;
      text-indent: -35px;
      border-radius: 50%;
      color: rgba(#868686, 0.7);
      background: linear-gradient(to bottom,
          lighten(#516acc, 20%) 0%,
          #516acc 100%);
    }
  }

  .card-item-title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .card-item-desc {
    font-size: 13px;
    color: #6f6f7b;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
  }

  .card-contact-wrapper {
    margin-top: 20px;
  }

  .card-contact {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
    cursor: pointer;

    &+& {
      margin-top: 16px;
    }

    svg {
      flex-shrink: 0;
      width: 30px;
      min-height: 34px;
      margin-right: 12px;
      transition: 0.3s;
      padding-right: 12px;
      border-right: 1px solid #dfe2ec;
    }
  }

  .contact-me {
    border: 0;
    outline: none;
    background: linear-gradient(to right,
        rgba(83, 200, 239, 0.8) 0%,
        rgba(81, 106, 204, 0.8) 96%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    padding: 12px 16px;
    width: 100%;
    border-radius: 5px;
    margin-top: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    transition: 0.3s;
  }

  footer {
    height: 12%;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: black;
    font-size: 16px;
    padding: 10px;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  border: 1px solid #888;
  width: 30%;
  margin: 15% auto;
  padding: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);

  p {
    font-weight: 700;
    text-align: center;
    color: black;
    font-size: 18px;
  }
}

.close {
  color: #aaa;
  align-self: flex-end;
  padding: 0px 5px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* ===== Mobile CSS ===== */
/* Pour les écrans de moins de 576px (mobiles) */
@media (max-width: 575px) {
  .div-hero-banner {
    .glass {
      display: flex;
      flex-direction: column;
      width: 100%;

      .navbar {
        padding: 0;
        height: 30%;
        width: 100%;
        justify-content: center;

        .logo {
          height: 60px;
          width: 70%;
          align-items: center;
        }
        .menu-header {
          display: none;
        }
      }

      .content{
        height: 70%;
        padding: 0px 20px;
        align-items: normal;

        .text{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          text-align: center;

          p {
            font-size: 18px;
          }

          .small {
            text-align: center;
            display: inline-block;
            font-size: 14px;
          }

          .btn-icon {
            display: none;
          }
        }

        .image {
          display: none;
        }
      }
    }
  }

  .mes-service {
    padding: 0;
    height: auto;

    .content-box {
      margin-top: 25px;
      padding: 0px 15px;
      flex-direction: column;

      .box {
        width: 100%;

        .box-feature {
          .icon {
            width: 20%;
          }
        }
      }
    }
  }

  .contact {
    padding: 10px 15px;
    .glass {
      padding: 0;
      width: 100%;

      h2 {
        padding: 15px 0px;
        font-size: 28px;
      }

      .formulaire {
        margin-top: 15px;
        .name {
          width: 100%;
          justify-content: space-between;
          padding: 0px 5px;
          label{
            width: 30%;
          }
          input {
            height: 70%;
            width: 65%;
          }
        }

        .surname {
          width: 100%;
          justify-content: space-between;
          padding: 0px 5px;
          label{
            width: 30%;
          }
          input {
            height: 70%;
            width: 65%;
          }
        }

        .email {
          width: 100%;
          justify-content: space-between;
          padding: 0px 5px;
          label{
            width: 30%;
          }
          input {
            height: 70%;
            width: 65%;
          }
        }

        .motif {
          width: 100%;
          justify-content: space-between;
          padding: 0px 5px;
          label{
            width: 30%;
          }
          select {
            height: 70%;
            width: 65%;
            font-size: 12px;
          }
        }

        .message {
          width: 100%;
          justify-content: space-between;
          padding: 0px 5px;
          label{
            width: 32%;
          }
          textarea {
            height: 70%;
            width: 65%;
            font-size: 14px;
          }
        }

        button {
          margin: 0;
          margin-right: 10px;
        }

        .info{
          width: 100%;
          padding: 25px 15px;
        }
      }
    }
  }

  .footer {
    .content {
      flex-direction: column;
      padding: 15px 10px;
      height: 90%;
      .logo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 80%;
        }
        p{
          margin-left: 0;
          margin-top: 15px;
          font-size: 14px;
          text-align: center;
        }
      }

      .card[data-state="#experience"] {
        height: 390px;
      }

      .card[data-state="#about"] {
        height: 390px;
      }

      .card[data-state="#contact"] {
        min-width: 300px;
        height: 390px;
      }

      .presentation {
        margin-top: 25px;
      }
    }

    footer {
      text-align: center;
      font-size: 16px;
    }
  }
}

/* ===== Scrollbar CSS ===== */

body {
  --sb-track-color: #85a0e975;
  --sb-thumb-color: #bfabc4;
  --sb-size: 15px;
}

body::-webkit-scrollbar {
  width: var(--sb-size)
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 2px;
  border: 1px solid #000000;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}